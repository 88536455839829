import React, { Fragment } from 'react';
import Cancelable from 'react-disposable-decorator';
import { handleError } from 'src/handle-error';
import { Scoped } from 'kremling';
import { find } from 'lodash';
// sofe
import { CpButton } from 'canopy-styleguide!sofe';
import { successToast, warningToast } from 'toast-service!sofe';
import { primaryNavHeightObs } from 'primary-navbar!sofe';
import { hasAccess, UserTenantProps } from 'cp-client-auth!sofe';
// project
import css from './left-panel.krem.css';
import { deleteNoticeTemplate, customizeTemplate } from '../../templates.resource.js';
import Timeline from '../../timeline/timeline.component.js';

@UserTenantProps()
@Cancelable
export default class LeftPanel extends React.Component {
  state = {
    height: 250,
    copying: false
  };

  componentDidMount() {
    this.props.cancelWhenUnmounted(primaryNavHeightObs.subscribe(height => this.setState({ height }), handleError));
  }

  static defaultProps = {
    cancelWhenUnmounted: () => {}
  };

  render() {
    const { template, viewOnly, noticeTypes } = this.props;
    let matchingTemplate, matchingGlobalTemplate;
    if (template && template.id) {
      matchingTemplate = find(noticeTypes, nTemplate => {
        return nTemplate.id === template.id;
      });
      matchingGlobalTemplate = find(noticeTypes, nTemplate => {
        return nTemplate.name === template.name && nTemplate.is_global === true && nTemplate.id !== template.id;
      });
    }
    const height = this.state.height /*primaryNavHeight*/ + 80; /* remaining amount of padding */

    return (
      <Scoped css={css}>
        <div className="leftPanelWrapper" style={{ height: `calc(100% - ${height}px` }}>
          <div className="timelineWrapper">
            <Timeline>
              <div style={{ height: '100%' }}>
                <Timeline.Title>Template workflow</Timeline.Title>
                {template && template.subtasks && template.subtasks.length > 0 ? (
                  <div className="sortableListWrapper">
                    <Timeline.SortableList onSortChange={this.handleSortChange} viewOnly={this.props.viewOnly}>
                      {template.subtasks.map(task => {
                        return <Timeline.Sub task={task} key={task.id} showDueDate={false} viewOnly={this.props.viewOnly} />;
                      })}
                    </Timeline.SortableList>
                  </div>
                ) : (
                  <Timeline.altText>
                    No template items have been added to the notice template. Once applied they will display here.
                  </Timeline.altText>
                )}
              </div>
            </Timeline>
          </div>
          <div>
            {!viewOnly && matchingGlobalTemplate && matchingGlobalTemplate.is_global === true && template.is_draft === false ? (
              <div className="leftPanelCard" style={{ backgroundColor: '#1f1e29' }}>
                <div style={{ paddingBottom: '8px' }}>
                  This template has been edited from the original version that Canopy provided. You can always reset the template to the
                  current Canopy version.
                </div>
                <CpButton btnType="primary" onClick={this.deleteTemplate}>
                  Match Canopy Template
                </CpButton>
              </div>
            ) : null}
            {this.props.smeEdit &&
              matchingTemplate &&
              !matchingTemplate.is_global &&
              matchingTemplate.is_draft === false &&
              hasAccess(this.props.loggedInUser)('sme_notices_publish') && (
                <div className="leftPanelCard" style={{ backgroundColor: '#1f1e29' }}>
                  <CpButton btnType="secondary" onClick={this.handlePublishClick}>
                    Save and Publish Template
                  </CpButton>
                </div>
              )}
            {this.props.template ? this.getSaveBlock(matchingTemplate) : null}
          </div>
        </div>
      </Scoped>
    );
  }

  handleGlobalEditSave = () => {
    const saveAsNew = true;
    this.props.savePublishRemove(saveAsNew);
  };

  handlePublishClick = () => {
    this.props.savePublishRemove();
  };

  getSaveBlock = matchingTemplate => {
    return (
      <div className="leftPanelCard cp-p-16" style={{ backgroundColor: '#1f1e29' }}>
        <div className="flex">{this.getSaveContents(matchingTemplate)}</div>
      </div>
    );
  };

  getSaveContents = matchingTemplate => {
    const cancelBtn = this.getButton({ click: this.props.cancel, text: 'Cancel' });
    const backBtn = this.getButton({ click: this.props.cancel, text: 'Back' });
    const normalSaveBtn = this.getButton({ click: this.handleSave, text: 'Save', type: 'primary' });
    const draftSaveBtn = this.getButton({ click: this.handleSaveAsDraft, text: 'Save', type: 'primary' });
    const reviewBtn = this.getButton({ click: this.handlePromoteToReady, text: 'To ready' });
    const toProgress = this.getButton({ click: this.handleSaveAsDraft, text: 'To progress' });
    const copyTemplate = this.getButton({ click: this.copyTemplate, text: 'Customize', type: 'primary' });
    const editGlobalSave = hasAccess(this.props.loggedInUser)('sme_notices_publish')
      ? this.getButton({ click: this.handleGlobalEditSave, text: 'Save and Publish', type: 'primary' })
      : null;
    if (this.props.new) {
      return (
        <Fragment>
          {draftSaveBtn}
          {cancelBtn}
        </Fragment>
      );
    } else if (this.props.smeEdit && matchingTemplate && !matchingTemplate.is_global) {
      return (
        <Fragment>
          {normalSaveBtn}
          {this.props.template.is_draft ? reviewBtn : toProgress}
          {cancelBtn}
        </Fragment>
      );
    } else if (matchingTemplate && matchingTemplate.is_global && this.props.smeEdit) {
      return (
        <Fragment>
          {editGlobalSave}
          {cancelBtn}
        </Fragment>
      );
    } else if (this.props.viewOnly && this.props.template.is_global) {
      return (
        <Fragment>
          {copyTemplate}
          {backBtn}
        </Fragment>
      );
    } else if (this.props.viewOnly) {
      return <Fragment>{backBtn}</Fragment>;
    } else {
      return (
        <Fragment>
          {normalSaveBtn}
          {cancelBtn}
        </Fragment>
      );
    }
  };

  getButton = config => {
    const { click, text, type = 'secondary' } = config;
    const loading = (this.props.isSaving || this.state.copying) && text !== 'Cancel' && text !== 'Back';
    return (
      <CpButton btnType={type} onClick={click} disabled={loading} showLoader={loading} className="cp-mr-8">
        {text}
      </CpButton>
    );
  };

  copyTemplate = () => {
    this.setState({ copying: true });
    this.props.cancelWhenUnmounted(
      customizeTemplate(this.props.template.id).subscribe(template => {
        this.setState({ copying: false });
        if (template.copying_document_template_error) {
          warningToast({ message: 'Some documents were unable to transfer to your customized notice template' });
        }
        // open new copied template
        window.location.href = `#/notices/global-settings/template/${template.id}/edit`;
      }, handleError)
    );
  };

  deleteTemplate = () => {
    this.props.cancelWhenUnmounted(
      deleteNoticeTemplate(this.props.template.id).subscribe(() => {
        successToast({ message: `Notice template "${this.props.template.name}" now matches the Canopy Template` });
        this.props.cancel();
      }, handleError)
    );
  };

  handleSaveAsDraft = () => {
    this.props.save(true);
  };

  handlePromoteToReady = () => {
    this.props.save(false);
  };

  handleSave = () => {
    this.props.save();
  };

  handleSortChange = results => {
    this.props.changeTemplateSort(results);
  };
}
