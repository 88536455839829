import * as React from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

export default class SortableList extends React.Component {
  state = {
    focus: undefined
  };

  render() {
    const items = this.props.children
      ? this.props.children.map(child => {
          const clone = React.cloneElement(child, { focus: this.state.focus });
          return {
            id: clone.props.task.id,
            content: clone
          };
        })
      : [];
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <div ref={provided.innerRef}>
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={this.props.viewOnly}>
                  {(provided, snapshot) => (
                    <div
                      className="sortableContainer"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...getItemStyle(snapshot.isDragging, provided.draggableProps.style)
                      }}
                      onMouseEnter={() => this.updateFocus(snapshot.isDragging, item.id)}
                      onMouseLeave={() => this.updateFocus(snapshot.isDragging, undefined)}
                      onFocus={() => this.updateFocus(snapshot.isDragging, item.id)}
                      onBlur={() => this.updateFocus(snapshot.isDragging, undefined)}>
                      {item.content}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  updateFocus = (isDragging, id) => {
    if (!isDragging) {
      this.setState({ focus: id });
    }
  };

  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.props.children
        ? this.props.children.map(child => {
            return child.props.task;
          })
        : [],
      result.source.index,
      result.destination.index
    );
    this.props.onSortChange(items);
  };
}

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // styles we need to apply on draggables
  ...draggableStyle
});
