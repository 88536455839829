import React from 'react';
import { Scoped } from 'kremling';
// project
import css from './timeline.krem.css';
import SortableList from './sortable-list.component.js';
import { getDueDateFromTask } from 'src/common/common.helpers.js';

export default class Timeline extends React.Component {
  static Title = TitleComponent;
  static Sub = SubTaskCard;
  static SortableList = SortableList;
  static altText = AltText;
  render() {
    return (
      <Scoped css={css}>
        <div className="timeLinePanel" style={{ backgroundColor: '#1f1e29' }}>
          {this.props.children}
        </div>
      </Scoped>
    );
  }
}

function TitleComponent(props) {
  return <div className="cps-white cps-wt-bold cps-subheader cp-pb-16">{props.children}</div>;
}

function SubTaskCard(props) {
  const dueDate = getDueDateFromTask(props.task);
  return (
    <div className="SubTaskCard">
      <div className="hoverMarkerWrapper">
        {props.focus === props.task.id && !props.viewOnly ? <i className="cps-icon cps-icon-drag cps-white" /> : null}
      </div>
      <div className="subTaskCardChildren">
        <div className="subTaskCardName cps-white cps-body cps-wt-bold">{props.task.name}</div>
        {props.showDueDate && <div className="cps-white">{dueDate ? `Due Date ${dueDate}` : 'No Due Date'}</div>}
      </div>
    </div>
  );
}

function AltText(props) {
  return <div className="cps-white cps-body">{props.children}</div>;
}
