import React from 'react';
import Cancelable from 'react-disposable-decorator';
import { CpIcon, CpDropdown, CpButton } from 'canopy-styleguide!sofe';
import { UserTenantProps } from 'cp-client-auth!sofe';
import { handleError } from 'src/handle-error';

// project
import FileUploader from 'src/common/file-uploader.component.js';

@UserTenantProps({ permissions: { hasFiles: 'files' } })
@Cancelable
export default class AddFileButton extends React.Component {
  static defaultProps = {
    cancelWhenUnmounted: () => {}
  };

  state = {
    fetchingUploader: false,
    uploaderVisible: false,
    uploaderFetched: false,
    uploaderType: 'file'
  };

  render() {
    const showCanopyFilesButton = this.props.permissions.hasFiles;
    return (
      <div>
        <CpDropdown
          contentWidth={230}
          renderTrigger={({ toggle }) => (
            <CpButton btnType="secondary" onClick={toggle}>
              Add file
            </CpButton>
          )}
          renderContent={() => (
            <div className="cp-select-list">
              {showCanopyFilesButton && (
                <button onClick={this.openFilePickerModal}>
                  <CpIcon name="folder-c" className="cp-select-list__icon-left" />
                  Choose from Canopy Files
                </button>
              )}
              <button onClick={this.showUploader}>
                <CpIcon name="misc-cloud-up-arrow" className="cp-select-list__icon-left" />
                Upload new
              </button>
              <button onClick={this.showFormUploader}>
                <CpIcon name="file-statement" className="cp-select-list__icon-left" />
                Add form
              </button>
              <button onClick={this.showDocUploader}>
                <CpIcon name="file-document" className="cp-select-list__icon-left" />
                Upload .docx File<span style={{ color: 'var(--cps-color-monsoon)', fontSize: 10 }}>&nbsp;(Editable)</span>
              </button>
            </div>
          )}
        />
        <FileUploader
          show={this.state.uploaderVisible}
          addFileRelationship={this.props.addRelationship}
          task={this.props.task}
          onClose={this.hideUploader}
          sync={true}
          uploaderType={this.state.uploaderType}
          purpose={this.props.purpose}
          revision={this.props.revision}
        />
      </div>
    );
  }

  openFilePickerModal = () => {
    this.setState({ uploaderVisible: false });
    SystemJS.import('docs-ui!sofe').then(docsUI => {
      docsUI.loadFilePickerModal().then(modal => {
        const modalProps = {
          showAllClients: true,
          clientId: this.props.clientId,
          disableClients: !this.props.clientId
        };

        this.props.cancelWhenUnmounted(modal(modalProps).subscribe(this.handleFiles));
      });
    }, handleError);
  };

  handleFiles = files => {
    this.props.addRelationship(this.props.task.id, files);
  };

  showDocUploader = () => {
    this.setState({ uploaderVisible: true, uploaderType: 'editable_doc' });
  };

  showFormUploader = () => {
    this.setState({ uploaderVisible: true, uploaderType: 'editable_form' });
  };

  showUploader = () => {
    this.setState({ uploaderVisible: true, uploaderType: 'file', fetchingUploader: true });
  };

  hideUploader = () => {
    this.setState({ uploaderVisible: false });
  };
}
