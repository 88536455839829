import { fetchAsObservable } from 'fetcher!sofe';
import { pluck } from 'rxjs/operators';
import { track } from 'cp-analytics';

export const uploadDocument = (document, purpose) => {
  const file = new FormData();
  file.append('file', document);
  file.append('name', document.name);
  file.append('type', 'notice');
  file.append('purpose', purpose);

  return fetchAsObservable(`/documents`, {
    method: 'POST',
    body: file
  }).pipe(pluck('document_id'));
};

export const getAdminForms = version => {
  return fetchAsObservable(`/wg/versions/${version}/admin`).pipe(pluck('groups'));
};

export function addFileRelationToTask(task, files) {
  if (!task) {
    throw new Error(`Cannot add a relationship to a task without the task and its id`);
  }

  if (!files) {
    throw new Error(`cannot add files to a task without the files`);
  }

  return fetchAsObservable(`api/tasks/${task.id}`, {
    method: 'PATCH',
    body: {
      tasks: {
        relationships: {
          files
        }
      }
    }
  });
}

export function notifyAnalytics(eventName, dataObject, productName = 'notices', featureName = 'notices') {
  let user = window.loggedInUser;
  if (!user) {
    return;
  }
  const user_id = user.id;
  const tenant_id = user.tenant_id;
  const dataObj = {
    user_id: user_id,
    tenant_id: tenant_id,
    ...dataObject
  };

  track(productName, featureName, eventName, dataObj);
}
