import { uniqBy, sortBy } from 'lodash';

export function getDueDateFromTask(task) {
  if (task === undefined || !task.dates) {
    return undefined;
  }
  const taskDateObj = task.dates.find(date => date.date_type_id === 'DUEDATE');
  if (taskDateObj && (taskDateObj.calculated_date || taskDateObj.date)) {
    return taskDateObj.date;
  } else {
    return undefined;
  }
}

export function preprocessTemplates(inboundTemplates) {
  const templates = uniqBy(inboundTemplates, item => item.id).sort(a => {
    if (a.is_global) {
      return 1;
    } else if (!a.is_global) {
      return -1;
    } else {
      return 0;
    }
  });
  const globalsWithoutOverrides = uniqBy(templates, item => item.name).filter(item => !!item.is_global);

  const localTemplates = templates.filter(item => !item.is_global);

  const merged = [...globalsWithoutOverrides, ...localTemplates];

  return sortBy(merged, template => template.name);
}

export function filterTemplatesByStatus(templates, status) {
  const { globals, locals } = divideTemplates(templates);

  if (status === 'inprogress') {
    // local only (no matching global) and status of draft
    return Object.keys(locals)
      .map(localKey => {
        if (globals[localKey] === undefined) {
          return locals[localKey];
        }
      })
      .filter(item => !!item && item.is_draft === true);
  } else if (status === 'ready') {
    // local only (no matching global) and status of not draft
    return Object.keys(locals)
      .map(localKey => {
        if (globals[localKey] === undefined) {
          return locals[localKey];
        }
      })
      .filter(item => !!item && item.is_draft === false);
  } else {
    // globals
    return Object.keys(globals)
      .map(globalKey => globals[globalKey])
      .filter(item => !!item);
  }
}

function divideTemplates(templates) {
  return templates.reduce(
    (acc, template) => {
      if (template.is_global) {
        acc.globals[template.name] = template;
      } else {
        acc.locals[template.name] = template;
      }
      return acc;
    },
    { globals: {}, locals: {} }
  );
}
