import React, { useState } from 'react';
import { CpSelectMultiPills } from 'canopy-styleguide!sofe';
import { find } from 'lodash';
import { useHasAccess } from 'cp-client-auth!sofe';

export const ToolsSelect = ({ tools, id, viewOnly, updateTools }) => {
  const hasNotesAccess = useHasAccess(['notes']);
  const [searchTools, setSearchTools] = useState('');
  const potentialTools = [
    {
      id: 'file',
      name: 'Adding a file'
    },
    {
      id: 'transcript',
      name: 'Requesting a transcript'
    },
    {
      id: 'note',
      name: 'Adding a note'
    }
  ];

  const getSelectableTools = () => {
    return potentialTools.filter(tool => {
      if (tool.id === 'note') {
        return hasNotesAccess;
      }
      return true;
    });
  };

  const getSelectedTools = () => {
    return tools.reduce((acc, toolString) => {
      const found = find(potentialTools, pt => pt.id === toolString);
      if (found) {
        return [...acc, found];
      } else {
        return acc;
      }
    }, []);
  };

  const handleToolChange = selections => {
    const tools = selections.map(item => item.id);
    updateTools(id, tools);
  };

  return (
    <div>
      <div>This subtask also requires:</div>
      <CpSelectMultiPills
        className="cp-mb-16"
        data={getSelectableTools()}
        placeholder="-- Select --"
        onChange={handleToolChange}
        renderPill={item => item.name}
        searchOnChange={setSearchTools}
        searchValue={searchTools}
        disabled={viewOnly}
        value={getSelectedTools()}
      />
    </div>
  );
};
