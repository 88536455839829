import * as React from 'react';
import Cancelable from 'react-disposable-decorator';
import { handleError } from 'src/handle-error';
import { get, isEmpty, remove, cloneDeep } from 'lodash';
import { Scoped, k, always } from 'kremling';
import { CpButton, CpLoader, CpModal, CpIcon, CpEmptyState } from 'canopy-styleguide!sofe';

// project imports
import { getAdminForms } from './task-methods.resource.js';

@Cancelable
export default class AddFormModal extends React.Component {
  state = {
    loadingForms: false,
    categorySelected: '',
    breadcrumbs: 'Forms',
    adminForms: null,
    stateSelected: '',
    selectedForms: [],
    selectedFormIds: [],
    selectedGroupIds: [],
    searchValue: '',
    allForms: [],
    filteredForms: []
  };

  componentDidMount() {
    // fetch the admin forms
    this.setState({ showFormUploader: true, loadingForms: true });
    this.props.cancelWhenUnmounted(
      getAdminForms(`${this.props.revision.version}-${this.props.revision.id}`).subscribe(groups => {
        const formGroups = groups[0].groups;
        let adminForms = {};
        adminForms.states = formGroups.filter(group => group.name === 'States');
        adminForms.federal = formGroups.filter(group => group.name === 'Federal');
        const allForms = this.getAllFormsList(adminForms);
        this.setState({ adminForms, loadingForms: false, allForms });
      }, handleError)
    );
  }

  // adds and removes a form from the selected form list
  addRemoveSelectedForm = (form, isFormSelected, groupId) => {
    if (isFormSelected) {
      return this.setState(prevState => {
        const selectedForms = remove(cloneDeep(prevState.selectedForms), formSelected => formSelected.id !== form.formId);
        const selectedFormIds = remove(cloneDeep(prevState.selectedFormIds), formId => formId !== form.formId);
        const selectedGroupIds = remove(cloneDeep(prevState.selectedGroupIds), group => group !== groupId);
        return {
          selectedForms,
          selectedFormIds,
          selectedGroupIds
        };
      });
    } else {
      return this.setState(prevState => {
        return {
          selectedForms: [...prevState.selectedForms, { id: form.formId, meta: form.meta, name: form.name, taxForm: form.meta.taxForm }],
          selectedFormIds: [...prevState.selectedFormIds, form.formId],
          selectedGroupIds: [...prevState.selectedGroupIds, groupId]
        };
      });
    }
  };

  // creates a list of all of the state forms and federal forms
  getAllFormsList = forms => {
    let allForms = get(forms, 'federal[0].groups[0].forms', []);

    get(forms, 'states[0].groups[0].groups', []).forEach(group => {
      allForms = allForms.concat(group.forms);
    });

    return allForms;
  };

  // handles searching forms while also persisting users folder location before search
  handleSearchValueChange = evt => {
    const value = evt.target.value.trimStart();
    if (!this.state.searchValue && value && value.length === 1) {
      // if its the first search value key typed, set previous states
      this.setState(prevState => {
        return {
          searchValue: value,
          breadcrumbs: 'Forms',
          categorySelected: '',
          prevBreadCrumbs: prevState.breadcrumbs,
          prevCategorySelected: prevState.categorySelected
        };
      }, this.filterForms);
    } else if (this.state.searchValue && !value) {
      // reset breadcrumbs and category to what they were
      this.setState(prevState => {
        return {
          searchValue: value,
          breadcrumbs: prevState.prevBreadCrumbs,
          categorySelected: prevState.prevCategorySelected,
          prevBreadCrumbs: '',
          prevCategorySelected: ''
        };
      }, this.filterForms);
    } else {
      this.setState({ searchValue: value }, this.filterForms);
    }
  };

  filterForms = () => {
    this.setState(prev => {
      const filteredForms = prev.allForms.filter(form => {
        return form.name.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1;
      });
      return {
        filteredForms
      };
    });
  };

  render() {
    const {
      categorySelected,
      loadingForms,
      breadcrumbs,
      adminForms,
      stateSelected,
      selectedFormIds,
      selectedGroupIds,
      selectedForms,
      searchValue,
      filteredForms
    } = this.state;
    return (
      <CpModal show={this.props.showFormUploader} onClose={this.props.onClose} width={832}>
        <Scoped css={css}>
          <CpModal.Header title="Forms" />
          <div className="add-forms-body">
            <div className="forms-breadcrumbs">{breadcrumbs}</div>
            <div className="search-forms">
              <div style={{ marginRight: 16, marginLeft: 16 }}>
                <CpIcon name="misc-magnifying-glass" />
              </div>
              <input
                className="form-search"
                type="text"
                placeholder="Search forms"
                disabled={loadingForms}
                value={searchValue}
                onChange={this.handleSearchValueChange}
              />
            </div>
            <div className="forms-select-section">
              <div className="form-categories">
                <div
                  className={always('form-category')
                    .m('form-category-selected', categorySelected === 'federal')
                    .m('disable-hover', searchValue)}
                  onClick={() => {
                    if (searchValue) return;
                    this.setState({ categorySelected: 'federal', breadcrumbs: 'Federal', stateSelected: '' });
                  }}>
                  <div style={{ marginRight: 16, marginLeft: categorySelected === 'federal' ? 13 : 16 }}>
                    {categorySelected === 'federal' ? (
                      <CpIcon name="folder-open" fill="var(--cps-color-primary)" />
                    ) : (
                      <CpIcon name="folder" />
                    )}
                  </div>
                  Federal
                </div>
                <div
                  className={always('form-category')
                    .m('form-category-selected', categorySelected === 'state')
                    .m('disable-hover', searchValue)}
                  onClick={() => {
                    if (searchValue) return;
                    this.setState({ categorySelected: 'state', breadcrumbs: 'State' });
                  }}>
                  <div style={{ marginRight: 16, marginLeft: categorySelected === 'state' ? 13 : 16 }}>
                    {categorySelected === 'state' ? (
                      <CpIcon name="folder-open" fill="var(--cps-color-primary)" />
                    ) : (
                      <CpIcon name="folder" />
                    )}
                  </div>
                  State
                </div>
              </div>
              <div className="form-navigation">
                {loadingForms ? (
                  <CpLoader size="md" />
                ) : !categorySelected && !searchValue ? (
                  <div style={{ marginTop: 24 }}>
                    <CpEmptyState img="folder-closed" text="Select federal or state folders to access your list of forms" />
                  </div>
                ) : searchValue ? (
                  // searched form list
                  <div className="forms-list">
                    {filteredForms.map(form => {
                      const isFormSelected = selectedFormIds.includes(form.formId);
                      return (
                        <div
                          key={`${form.formId}-${form.name}`}
                          className={always('federal-form-item').m('item-selected', isFormSelected)}
                          onClick={() => this.addRemoveSelectedForm(form, isFormSelected, form.meta.groupId)}>
                          <CpIcon name="file-document" />
                          <span style={{ marginLeft: 16 }}>{form.name}</span>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  // state and federal form list
                  <div className="forms-list">
                    {categorySelected === 'federal'
                      ? get(adminForms, 'federal[0].groups[0].forms', []).map(form => {
                          const isFormSelected = selectedFormIds.includes(form.formId);
                          return (
                            <div
                              key={`${form.formId}-${form.name}`}
                              className={always('federal-form-item').m('item-selected', isFormSelected)}
                              onClick={() => this.addRemoveSelectedForm(form, isFormSelected)}>
                              <CpIcon name="file-document" />
                              <span style={{ marginLeft: 16 }}>{form.name}</span>
                            </div>
                          );
                        })
                      : get(adminForms, 'states[0].groups[0].groups', []).map(group => {
                          const isOpen = stateSelected === group.name;
                          const hasForms = !isEmpty(group.forms);

                          if (!hasForms) return;

                          return (
                            <>
                              <div
                                className={always('state-folder').m('item-selected', isOpen || selectedGroupIds.includes(group.id))}
                                onClick={() =>
                                  this.setState(prevState => {
                                    let stateSelected = group.name;
                                    let breadcrumbs = `State > ${group.name}`;
                                    if (group.name === prevState.stateSelected) {
                                      stateSelected = '';
                                      breadcrumbs = 'State';
                                    }
                                    return { stateSelected, breadcrumbs };
                                  })
                                }>
                                <div>
                                  {isOpen ? <CpIcon name="folder-open" /> : <CpIcon name="folder" />}
                                  <span style={{ marginLeft: 16 }}>{group.name}</span>
                                </div>
                                {isOpen && <CpIcon name="caret-large-down" />}
                              </div>
                              {isOpen &&
                                group.forms.map(form => {
                                  const isFormSelected = selectedFormIds.includes(form.formId);
                                  return (
                                    <div
                                      key={`${form.formId}-${form.name}`}
                                      className={always('state-form-item').m('item-selected', isFormSelected)}
                                      onClick={() => this.addRemoveSelectedForm(form, isFormSelected, group.id)}>
                                      <CpIcon name="file-document" />
                                      <span style={{ marginLeft: 16 }}>{form.name}</span>
                                    </div>
                                  );
                                })}
                            </>
                          );
                        })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="add-form-footer">
            <div>
              <CpButton btnType="primary" disabled={isEmpty(selectedFormIds)} onClick={() => this.props.addFormsToTemplate(selectedForms)}>
                Add
              </CpButton>
              <CpButton btnType="flat" onClick={() => this.setState({ showFormUploader: false }, this.props.onClose)}>
                Cancel
              </CpButton>
            </div>
            {selectedFormIds.length > 0 && (
              <div className="form-select-count">
                ( <strong>{selectedFormIds.length}</strong> Selected )
              </div>
            )}
          </div>
        </Scoped>
      </CpModal>
    );
  }
}

const css = k`
  .forms-select-section {
    display: flex;
  }

  .search-forms {
    display: flex;
    align-items: center;
    background: var(--cps-color-medium-well);
    border-bottom: 1px solid var(--cps-color-border);
    height: 42px;
    width: 100%;
  }

  .forms-breadcrumbs {
    display: flex;
    align-items: center;
    height: 32px;
    width: 100%;
    margin-left: 40px;
    color: var(--cps-color-secondary-text);
  }

  .form-categories {
    width: 18rem;
    height: 40rem;
    background: var(--cps-color-medium-well);
    border-right: 1px solid var(--cps-color-border);
  }

  .form-category {
    display: flex;
    align-items: center;
    height: 42px;
    border-bottom: 1px solid var(--cps-color-border);
  }

  .form-category:hover {
    background: var(--cps-color-menu-hover-bg);
    cursor: pointer;
  }

  .disable-hover:hover {
    background: var(--cps-color-medium-well);
    cursor: default;
  }

  .form-category-selected {
    color: var(--cps-color-primary);
    border-left: 3px solid var(--cps-color-primary);
  }

  .form-navigation {
    height: 40rem;
    width: 100%;
  }

  .forms-list {
    height: 100%;
    overflow: auto;
  }

  .federal-form-item {
    height: 45px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    cursor: pointer;
    border-bottom: 1px solid var(--cps-color-border);
  }

  .federal-form-item:hover {
    background: var(--cps-color-pill-bg);
  }

  .state-folder {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    cursor: pointer;
    border-bottom: 1px solid var(--cps-color-border);
    padding-right: 16px;
  }

  .state-folder:hover {
    background: var(--cps-color-pill-bg);
  }

  .state-form-item {
    height: 45px;
    padding-left: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid var(--cps-color-border);
  }

  .state-form-item:hover {
    background: var(--cps-color-pill-bg);
  }

  .item-selected {
    background: var(--cps-color-pill-bg);
    font-weight: bold;
  }

  .add-form-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.6rem;
    border-top: solid .1rem var(--cps-color-athens);
    flex-grow: 0;
    flex-shrink: 0;
  }

  .form-select-count {
    color: var(--cps-color-secondary-text);
    margin-right: 16px;
  }

  .form-search {
    width: 100%;
    height: 40px;
    border: none;
    font-size: 13px;
    background-color: var(--cps-color-medium-well);
  }

  .form-search:focus {
    outline: none;
  }
`;
