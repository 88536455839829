import React from 'react';
import { get, cloneDeep } from 'lodash';
// project
import TemplateCard from './template-card.component.js';

export default class EditTemplateCards extends React.Component {
  subs = {};

  render() {
    const { task, viewOnly } = this.props;
    const subTasks = get(task, 'subtasks', []);
    return (
      <div style={{ width: '100%' }}>
        <TemplateCard
          ref={el => (this.rootTask = el)}
          task={task}
          isSub={false}
          new={this.props.new}
          smeEdit={this.props.smeEdit}
          updateName={this.props.updateTaskName}
          updateDescription={this.props.updateTaskDescription}
          updateTools={this.props.updateTools}
          updateExplanation={this.props.updateExplanation}
          addRelationship={this.props.addRelationship}
          removeRelationship={this.props.removeRelationship}
          viewOnly={viewOnly}
          handleHideScrollbar={this.props.handleHideScrollbar}
          revision={this.props.revision}
        />
        {subTasks.length > 0 ? (
          <div className="cp-pl-24">
            {subTasks.map(task => (
              <TemplateCard
                ref={el => (this.subs[task.id] = el)}
                key={task.id}
                task={task}
                isSub={true}
                new={this.props.new}
                smeEdit={this.props.smeEdit}
                updateName={this.props.updateTaskName}
                updateDescription={this.props.updateTaskDescription}
                updateTools={this.props.updateTools}
                addRelationship={this.props.addRelationship}
                removeRelationship={this.props.removeRelationship}
                removeSubtask={this.props.removeSubtask}
                viewOnly={viewOnly}
                handleHideScrollbar={this.props.handleHideScrollbar}
                revision={this.props.revision}
              />
            ))}
          </div>
        ) : null}
        {!viewOnly && (
          <div className="addNewTask cp-pb-12">
            <hr />
            <a className={'buttonCard cp-pt-12 cp-pb-12 cps-btn cps-bg-white cps-gray1'} onClick={this.props.addSubtask}>
              <div className="addTaskContents cps-color-secondary-text">
                <i className="cps-icon cps-icon-subtask megaIcon" />
                <div className="cp-mt-4">Add a subtask</div>
              </div>
            </a>
            <hr />
          </div>
        )}
      </div>
    );
  }

  pullActualTask = () => {
    const task = cloneDeep(this.props.task);
    task.description = this.rootTask && this.rootTask.getDescription();
    task.subtasks = task.subtasks.map(task => {
      task.name = task.name || 'Subtask Title';
      task.description = this.subs[task.id].getDescription();
      return task;
    });
    return task;
  };
}
