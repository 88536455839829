import { uniqueId, cloneDeep, findIndex, isArray, uniqBy, isNil, reverse } from 'lodash';

export function updateTools(task, id, tools) {
  let newTask;
  newTask = cloneDeep(task);
  if (task.id === id) {
    newTask = updateTask(task, tools, 'tools');
  } else {
    newTask.subtasks = updateSubTask(newTask.subtasks, id, task => updateTask(task, tools, 'tools'));
  }
  return newTask;
}

export function updateTaskName(task, id, newName) {
  let newTask;
  newTask = cloneDeep(task);
  if (task.id === id) {
    newTask = updateTask(task, newName, 'name');
    newTask = updateServiceFields(newTask, newName, 'notice_type');
  } else {
    newTask.subtasks = updateSubTask(newTask.subtasks, id, task => updateTask(task, newName, 'name'));
  }
  return newTask;
}

export function updateTaskDescription(task, id, newDescription) {
  let newTask;
  newTask = cloneDeep(task);
  if (task.id === id) {
    newTask = updateTask(task, newDescription, 'description');
    newTask = updateServiceFields(newTask, newDescription, 'notice_type');
  } else {
    newTask.subtasks = updateSubTask(newTask.subtasks, id, task => updateTask(task, newDescription, 'description'));
  }
  return newTask;
}

export function updateNoticeExplanation(task, id, newExplanation) {
  let newTask;
  newTask = cloneDeep(task);
  if (task.id === id) {
    newTask = updateServiceFields(task, newExplanation, 'notice_explanation');
  }
  return newTask;
}

export function addRelationship(task, id, relationship, type = 'files') {
  let newTask = cloneDeep(task);
  if (task.id === id) {
    newTask = addRelationshipToTask(task, relationship, type);
  } else {
    newTask.subtasks = updateSubTask(newTask.subtasks, id, task => addRelationshipToTask(task, relationship, type));
  }
  return newTask;
}

export function removeRelationship(task, id, relationshipId, type = 'files') {
  let newTask = cloneDeep(task);
  if (task.id === id) {
    if (type === 'files') {
      newTask = deleteFileRelationship(task, relationshipId);
    } else if (type === 'document_templates') {
      newTask = deleteDocTemplateRelationship(task, relationshipId);
    } else if (type === 'forms') {
      newTask = deleteFormsRelationship(task, relationshipId);
    } else {
      newTask = removeRelationshipFromTask(task, relationshipId, type);
    }
  } else {
    newTask.subtasks = updateSubTask(newTask.subtasks, id, task => {
      if (type === 'files') {
        return deleteFileRelationship(task, relationshipId);
      } else if (type === 'document_templates') {
        return deleteDocTemplateRelationship(task, relationshipId);
      } else if (type === 'forms') {
        return deleteFormsRelationship(task, relationshipId);
      }
      {
        return removeRelationshipFromTask(task, relationshipId, type);
      }
    });
  }
  return newTask;
}

export function addSubtask(task) {
  const newTask = cloneDeep(task);
  if (!newTask.subtasks) {
    newTask.subtasks = [];
  }
  newTask.subtasks.push(emptyTask());
  return newTask;
}

export function removeSubtask(task, taskId) {
  return { ...task, subtasks: task.subtasks.filter(sub => sub.id !== taskId) };
}

export function convertExistingToNew(task) {
  let newTask = cloneDeep(task);
  if (newTask.subtasks) {
    newTask.subtasks = newTask.subtasks.map(sub => stripRelationships(sub));
  }
  newTask = stripRelationships(newTask, false);
  return newTask;
}

function stripRelationships(task, isSub) {
  const relationships = cloneDeep(task.relationships);
  if (relationships && relationships.files) {
    relationships.files = relationships.files.map(file => {
      delete file.deleted_at;
      delete file.created_at;
      return file;
    });
  }
  return { ...task, relationships, id: uniqueId(isSub ? 'subtask_' : 'task_') };
}

function addRelationshipToTask(task, relationship, type = 'files') {
  const newTask = { ...task };
  if (isArray(newTask.relationships[type])) {
    newTask.relationships[type] = getNewTaskRelationship(type, newTask.relationships[type], relationship, isArray(relationship));
  } else {
    newTask.relationships[type] = [];
    newTask.relationships[type] = getNewTaskRelationship(type, newTask.relationships[type], relationship, isArray(relationship));
  }
  return newTask;
}

function getNewTaskRelationship(type, relationship, newRelationship, isArray) {
  if (isArray) {
    return reverse(uniqBy([...newRelationship, ...relationship], item => item.id));
  } else {
    return reverse(uniqBy([newRelationship, ...relationship], item => item.id));
  }
}

function updateTask(task, value, key) {
  const newTask = cloneDeep(task);
  newTask[key] = value;
  return newTask;
}

function updateServiceFields(task, value, key) {
  const newTask = cloneDeep(task);
  newTask['service_fields'][key] = value;
  return newTask;
}

function updateSubTask(tasks, id, predicate) {
  return tasks.map(task => {
    if (task.id !== id) {
      return task;
    } else {
      return predicate(task);
    }
  });
}

function removeRelationshipFromTask(task, relationshipId, type = 'files') {
  const newTask = cloneDeep(task);
  const arrayOfType = newTask.relationships[type];
  const index = findIndex(arrayOfType, relationship => relationship.id === relationshipId);
  const before = arrayOfType.slice(0, index);
  const after = arrayOfType.slice(index + 1);
  newTask.relationships[type] = [...before, ...after];
  return newTask;
}

function deleteFileRelationship(task, relationshipId) {
  const newTask = cloneDeep(task);
  const arrayOfType = newTask.relationships['files'];
  const index = findIndex(arrayOfType, relationship => relationship.id === relationshipId);
  const before = arrayOfType.slice(0, index);
  const after = arrayOfType.slice(index + 1);
  newTask.relationships['files'] = [...before, { ...arrayOfType[index], delete: true }, ...after];
  return newTask;
}

function deleteDocTemplateRelationship(task, relationshipId) {
  const newTask = cloneDeep(task);
  const arrayOfType = newTask.relationships['document_templates'];
  const index = findIndex(arrayOfType, relationship => relationship.id === relationshipId);
  const before = arrayOfType.slice(0, index);
  const after = arrayOfType.slice(index + 1);
  newTask.relationships['document_templates'] = [...before, { ...arrayOfType[index], delete: true }, ...after];
  return newTask;
}

function deleteFormsRelationship(task, relationshipId) {
  const newTask = cloneDeep(task);
  const arrayOfType = newTask.relationships['forms'];
  const index = findIndex(arrayOfType, relationship => relationship.id === relationshipId);
  const before = arrayOfType.slice(0, index);
  const after = arrayOfType.slice(index + 1);
  newTask.relationships['forms'] = [...before, { ...arrayOfType[index], delete: true }, ...after];
  return newTask;
}

function emptyTask() {
  return {
    id: uniqueId('subtask_'),
    name: '',
    description: '<p></p>',
    dates: [],
    reminders: [],
    relationships: {},
    tools: []
  };
}

export function filterFiles(files) {
  return files.filter(file => !isNil(file.name) && !file.deleted_at);
}

export function filterDocs(docs) {
  return docs.filter(doc => !isNil(doc.filename) && !doc.deleted_at);
}

export function filterForms(form) {
  return form.filter(form => !isNil(form.name) && !form.deleted_at);
}
