import * as React from 'react';
import Cancelable from 'react-disposable-decorator';
import { handleError } from 'src/handle-error';
import { Scoped } from 'kremling';
import { Link } from 'react-router-dom';
// sofe imports
import { CpLoader } from 'canopy-styleguide!sofe';
import { NavContent } from 'primary-navbar!sofe';
import { hasAccess, UserTenantProps } from 'cp-client-auth!sofe';
// project imports
import NoticeTemplateRow from './notice-template-row/notice-template-row.component.js';
import { getTeamMembers, getNoticeTypes } from 'src/common/common.resource.js';
import css from './templates-list.krem.css';
import { fetchTemplate } from '../templates.resource.js';

const Timeline = React.lazy(async () => {
  const { getTimeline } = await SystemJS.import('tasks-ui!sofe');
  return await getTimeline();
});

@UserTenantProps()
@Cancelable
export default class TemplateList extends React.Component {
  static defaultProps = {
    cancelWhenUnmounted: () => {}
  };

  state = {
    noticeTemplates: [],
    selectedNoticeTemplate: null,
    teamMembers: [],
    loadingTemplates: true,
    loadingTeamMembers: true,
    searchValue: '',
    filteredTemplates: [],
    loadingParcel: false
  };

  componentDidMount() {
    this.props.cancelWhenUnmounted(
      getNoticeTypes(undefined, true).subscribe(results => {
        this.setState({ noticeTemplates: results, loadingTemplates: false, filteredTemplates: results });
      }, handleError),
      getTeamMembers().subscribe(results => {
        this.setState({ teamMembers: results, loadingTeamMembers: false });
      })
    );
  }

  render() {
    const noticeTemplates = this.state.filteredTemplates;
    const user = this.props.loggedInUser;

    return (
      <NavContent hasTopnavSecondary={true}>
        <Scoped css={css}>
          <div className="notice-template-main-div cps-flexible-focus">
            <div className="cps-bg-white cps-card__height-2 left-flex-div">
              <h1 className="cps-subheader listHeader cp-mt-0">
                <div>Notice Templates</div>
                {hasAccess(user)('sme_notices') && (
                  <Link className="cps-btn +primary" to="/notices/global-settings/sme">
                    SME Notice Manager
                  </Link>
                )}
              </h1>
              <div className="searchBox cps-bg-background">
                {this.state.searchValue === '' ? (
                  <i className="templateSearchIcon cps-icon cps-icon-search cps-color-secondary-text"></i>
                ) : (
                  <i
                    className="templateSearchIcon cps-icon cps-icon-close +pointer cps-color-secondary-text"
                    onClick={this.clearSearch}
                    data-testid="clear-search"
                  />
                )}
                <input
                  className="templateSearch"
                  placeholder="Search notice templates"
                  value={this.state.searchValue}
                  onChange={this.handleSearchValueChange}
                />
              </div>
              <div className="table">
                {this.state.loadingTemplates || this.state.loadingTeamMembers ? (
                  <div className="cp-p-16" data-testid="loader">
                    <CpLoader />
                  </div>
                ) : (
                  noticeTemplates.map((template, index) => (
                    <React.Fragment key={template.id}>
                      <NoticeTemplateRow
                        template={template}
                        teamMembers={this.state.teamMembers}
                        handleNoticeClick={this.handleNoticeClick}
                        refetchNotices={this.fetchNotices}
                        selectedNoticeTemplate={this.state.selectedNoticeTemplate}
                      />
                      {index + 1 !== noticeTemplates.length ? <hr className="cp-m-0" /> : null}
                    </React.Fragment>
                  ))
                )}
              </div>
            </div>
            <div className="right-flex-div">
              <div className="right-flex-item">
                <React.Suspense fallback={<CpLoader />}>
                  <Timeline
                    timelineTitle={this.state.selectedNoticeTemplate ? `Workflow: ${this.state.selectedNoticeTemplate.name}` : null}
                    timelineDescription={
                      !this.state.selectedNoticeTemplate
                        ? 'Select a notice template to preview its workflow here.'
                        : 'No template items have been added to the notice template. Once applied they will display here.'
                    }
                    task={this.state.selectedNoticeTemplate}
                    timelineHeight={'100%'}
                    notTemplate={true}
                  />
                </React.Suspense>
                {this.state.loadingParcel && (
                  <div className="cp-p-16" data-testid="loader">
                    <CpLoader />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Scoped>
      </NavContent>
    );
  }

  handleSearchValueChange = evt => {
    const value = evt.target.value;
    this.setState({ searchValue: value }, this.filterNoticeTemplates);
  };

  fetchNotices = () => {
    this.props.cancelWhenUnmounted(
      getNoticeTypes(undefined, true).subscribe(results => {
        this.setState({ noticeTemplates: results, loadingTemplates: false, filteredTemplates: results });
      }, handleError)
    );
  };

  handleErrorAndClose = err => {
    handleError(err);
  };

  clearSearch = () => {
    this.setState(prev => {
      return {
        searchValue: '',
        filteredTemplates: prev.noticeTemplates
      };
    });
  };

  handleNoticeClick = template => {
    this.setState({ loadingParcel: true });

    const subscription = fetchTemplate(template.id).subscribe(results => {
      this.setState({ selectedNoticeTemplate: results, loadingParcel: false });
    }, handleError);
    this.props.cancelWhenUnmounted(subscription);
  };

  filterNoticeTemplates = () => {
    this.setState(prev => {
      const filteredTemplates = prev.noticeTemplates.filter(noticeTemplate => {
        return noticeTemplate.name.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1;
      });
      return {
        filteredTemplates
      };
    });
  };
}
