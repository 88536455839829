import React from 'react';
import Cancelable from 'react-disposable-decorator';
import { handleError } from 'src/handle-error';
import { Scoped, always } from 'kremling';
import { get, isEmpty } from 'lodash';
import { switchMap, tap } from 'rxjs/operators';
import { taxResVersionAsObservable } from 'sme-version!sofe';

// sofe imports
import { CpLoader } from 'canopy-styleguide!sofe';
import { NavContent } from 'primary-navbar!sofe';
import { successToast } from 'toast-service!sofe';
import { hasAccess, UserTenantProps } from 'cp-client-auth!sofe';
// project
import {
  getDateTypes,
  saveNoticeTemplate,
  publishGlobalTemplate,
  deleteNoticeTemplate,
  deleteAccusoftDocs,
  getRevisionsList
} from './template-editor.resource.js';
import { fetchTemplate } from '../templates.resource.js';
import css from './template-editor.krem.css';
import EditTemplateCards from './edit-template-cards/edit-template-cards.component.js';
import LeftPanel from './left-panel/left-panel.component.js';
import * as helper from './template-editor.helper.js';
import { getNoticeTypes } from 'src/common/common.resource.js';

@UserTenantProps()
@Cancelable
export default class TemplateEditor extends React.Component {
  state = {
    template: undefined,
    loadingTemplate: true,
    dateTypes: [],
    noticeTypes: [],
    isSaving: false,
    subTaskChange: false,
    documentsRemoved: [],
    documentsAdded: [],
    hideScrollbar: false,
    revision: {}
  };

  static defaultProps = {
    cancelWhenUnmounted: () => {},
    smeEdit: false,
    new: false
  };

  componentDidMount() {
    if (!this.props.new) {
      this.fetchTemplateById();
    } else {
      this.getEmptyTask();
    }

    this.props.cancelWhenUnmounted(
      taxResVersionAsObservable.subscribe(({ revision }) => {
        return this.fetchRevisionData(revision.version);
      })
    );

    this.fetchDateTypes();
    this.fetchNoticeTypes();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.new) {
      const templateId = get(this.props, 'match.params.templateId');
      const prevTemplateId = get(prevProps, 'match.params.templateId');
      if (prevTemplateId !== templateId) {
        this.fetchTemplateById();
        this.fetchNoticeTypes();
      }
    }
  }

  render() {
    const { template, subTaskChange, noticeTypes, hideScrollbar } = this.state;
    const user = this.props.loggedInUser;
    const hasTemplateRights = hasAccess(user)('templates_notices') || get(template, 'created_by') === user.id;
    let viewOnly = get(template, 'is_global') || get(template, 'is_draft') || !hasTemplateRights;
    if (this.props.smeEdit) {
      viewOnly = false;
    }

    return (
      <Scoped css={css}>
        <div className={always('templateWrapper').maybe('hideScrollbar', hideScrollbar)}>
          <NavContent hasTopnavSecondary={true} className="absolute">
            <div className="leftTemplateArea">
              <LeftPanel
                template={template}
                viewOnly={viewOnly}
                save={this.save}
                cancel={() => this.routeToList(this.state.documentsAdded)}
                isSaving={this.state.isSaving}
                noticeTypes={noticeTypes}
                changeTemplateSort={this.handleSortChange}
                smeEdit={this.props.smeEdit}
                new={this.props.new}
                savePublishRemove={this.savePublishRemove}
              />
            </div>
          </NavContent>
          <NavContent hasTopnavSecondary={true}>
            <div className="templateArea">
              <div className="rightTemplateArea">
                {template && !subTaskChange ? (
                  <EditTemplateCards
                    ref={el => (this.editCards = el)}
                    task={template}
                    viewOnly={viewOnly}
                    updateTaskName={this.updateTaskName}
                    updateTaskDescription={this.updateTaskDescription}
                    updateTools={this.updateTools}
                    updateExplanation={this.updateExplanation}
                    addRelationship={this.addRelationship}
                    removeRelationship={this.removeRelationship}
                    removeSubtask={this.removeSubtask}
                    addSubtask={this.addSubtask}
                    new={this.props.new}
                    smeEdit={this.props.smeEdit}
                    handleHideScrollbar={this.handleHideScrollbar}
                    revision={this.state.revision}
                  />
                ) : (
                  <CpLoader />
                )}
              </div>
            </div>
          </NavContent>
        </div>
      </Scoped>
    );
  }

  fetchRevisionData = version => {
    this.props.cancelWhenUnmounted(
      getRevisionsList(version).subscribe(version => {
        return this.setState({ revision: version.revisions.find(rev => rev.status === 'live' || rev.isLive) });
      })
    );
  };

  fetchTemplateById = () => {
    const templateId = get(this.props, 'match.params.templateId');
    this.props.cancelWhenUnmounted(
      fetchTemplate(templateId).subscribe(results => {
        this.setState({
          loadingTemplate: false,
          template: results
        });
      }, handleError)
    );
  };

  fetchDateTypes = () => {
    this.props.cancelWhenUnmounted(
      getDateTypes(this.props.loggedInUser.id).subscribe(dateTypes => this.setState({ dateTypes }), handleError)
    );
  };

  fetchNoticeTypes = () => {
    this.props.cancelWhenUnmounted(
      getNoticeTypes().subscribe(types => {
        this.setState({ noticeTypes: types });
      }, handleError)
    );
  };

  handleSortChange = newOrder => {
    this.setState({ subTaskChange: true }, () => {
      this.reorderSubTasks(newOrder);
    });
  };

  handleHideScrollbar = ({ hideScrollbar }) => {
    this.setState({ hideScrollbar });
  };

  reorderSubTasks = newOrder => {
    this.setState(
      prev => {
        const { template } = prev;
        const task = { ...template, subtasks: newOrder };
        return {
          template: task
        };
      },
      () => {
        setTimeout(() => {
          this.setState({ subTaskChange: false });
        }, 400);
      }
    );
  };

  updateTaskName = (id, newName) => {
    this.setState(prevState => {
      const task = helper.updateTaskName(prevState.template, id, newName);
      return {
        template: task
      };
    });
  };

  updateTaskDescription = (id, newDescription) => {
    this.setState(prevState => {
      const task = helper.updateTaskDescription(prevState.template, id, newDescription);
      return {
        template: task
      };
    });
  };

  updateExplanation = (id, newExplanation) => {
    this.setState(prevState => {
      const task = helper.updateNoticeExplanation(prevState.template, id, newExplanation);
      return {
        template: task
      };
    });
  };

  updateTools = (id, tools) => {
    this.setState(prevState => {
      const task = prevState.template;
      return {
        template: helper.updateTools(task, id, tools)
      };
    });
  };

  addRelationship = (taskId, relationship, type = 'files') => {
    let documentsAdded = [...this.state.documentsAdded];
    if (type === 'document_templates') {
      documentsAdded = [...this.state.documentsAdded, { id: relationship.id }];
    }

    this.setState(prevState => {
      const task = prevState.template;
      return {
        template: helper.addRelationship(task, taskId, relationship, type),
        documentsAdded
      };
    });
  };

  removeRelationship = (taskId, relationshipId, type = 'files') => {
    let documentsRemoved = [...this.state.documentsRemoved];
    if (type === 'document_templates') {
      documentsRemoved = [...this.state.documentsRemoved, { id: relationshipId }];
    }

    this.setState(prevState => ({
      template: helper.removeRelationship(prevState.template, taskId, relationshipId, type),
      documentsRemoved
    }));
  };

  removeSubtask = taskId => {
    const subtask = this.state.template.subtasks.find(task => task.id === taskId);
    const docTemplates = get(subtask, 'relationships.document_templates', []);

    this.setState(prevState => ({
      template: helper.removeSubtask(prevState.template, taskId),
      documentsRemoved: [...this.state.documentsRemoved, ...docTemplates]
    }));
  };

  addSubtask = () => {
    this.setState(prevState => ({ template: helper.addSubtask(prevState.template) }));
  };

  routeToList = (documentsToDelete = []) => {
    const cb = () => {
      if (this.props.smeEdit) {
        this.props.history.push('/notices/global-settings/sme');
      } else {
        this.props.history.push('/notices/global-settings');
      }
    };

    // if any docs have been added or removed that needed to be deleted in the accusoft db, we clean 'em out here
    if (!isEmpty(documentsToDelete)) {
      this.props.cancelWhenUnmounted(
        deleteAccusoftDocs(documentsToDelete).subscribe(() => {
          cb();
        }, handleError)
      );
    } else {
      cb();
    }
  };

  save = is_draft => {
    if (this.editCards) {
      this.setState({ isSaving: true }, () => {
        const task = this.editCards && this.editCards.pullActualTask();
        if (is_draft !== undefined) {
          task.is_draft = is_draft;
        }
        const isGlobal = this.state.noticeTypes.reduce((acc, type) => {
          if (type.id === task.id) {
            return type.is_global || acc;
          } else {
            return acc;
          }
        }, false);
        this.saveTemplate(task, isGlobal, this.props.new);
      });
    }
  };

  saveTemplate = (task, isGlobal, isNew) => {
    this.props.cancelWhenUnmounted(
      saveNoticeTemplate(task, isGlobal, isNew).subscribe(
        () => {
          if (isGlobal) {
            successToast({ message: `Canopy notice template "${task.name}" was customized` });
          } else if (isNew) {
            successToast({ message: `New Notice template "${task.name}" was created` });
          } else {
            successToast({ message: `Notice template "${task.name}" updated` });
          }

          this.routeToList(this.state.documentsRemoved);
        },
        err => {
          this.setState({ isSaving: false });
          handleError(err);
        }
      )
    );
  };

  savePublishRemove = (saveAsNew = false) => {
    let templateId;
    const savePublishRemove$ = saveNoticeTemplate(this.state.template, undefined, saveAsNew).pipe(
      switchMap(template => {
        templateId = template.template_tasks.id;
        return publishGlobalTemplate(templateId);
      }),
      tap(() => {
        successToast({ message: `Task saved and published successfully` });
      }),
      switchMap(() => {
        return deleteNoticeTemplate(templateId);
      })
    );

    this.props.cancelWhenUnmounted(
      savePublishRemove$.subscribe(
        () => {
          this.routeToList(this.state.documentsRemoved);
        },
        err => {
          err.toastMessage = 'Problem completing the save and publish operation';
          handleError(err);
        }
      )
    );
  };

  getEmptyTask = () => {
    const emptyTask = {
      id: 'NEW',
      name: '',
      subtasks: [],
      relationships: {
        files: [],
        notes: [],
        transcripts: []
      },
      service_fields: {
        notice_explanation: '',
        notice_type: ''
      },
      is_visible_to_all: true,
      is_editable_by_all: true,
      service_type: 'notice',
      dates: [],
      skip_assignees: true,
      recurrence: null,
      parent_template_id: null
    };
    this.setState({ template: emptyTask });
  };
}
