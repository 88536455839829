import React, { Fragment } from 'react';
import Cancelable from 'react-disposable-decorator';
import { handleError } from 'src/handle-error';
import { Scoped, a } from 'kremling';
import { get, find } from 'lodash';
import { CpIcon, CpLoader, CpDropdown, CpButton } from 'canopy-styleguide!sofe';
import { hasAccess, UserTenantProps } from 'cp-client-auth!sofe';
import { warningToast } from 'toast-service!sofe';
import { DateTime } from 'luxon';
// project
import styles from './notice-template-row.krem.css';
import DeleteTemplateConfirmationModal from './delete-template-modal/delete-template-modal.component.js';
import CopyTempalateModal from './copy-template-modal/copy-template-modal.component.js';
import { deleteNoticeTemplate, copyAndSaveNoticeTemplate, customizeTemplate } from 'src/templates/templates.resource.js';

@UserTenantProps()
@Cancelable
export default class NoticeTemplateRow extends React.Component {
  state = {
    visibleIcons: false,
    deleteConfirm: false,
    restoring: false,
    copyModal: false,
    loading: false
  };

  render() {
    const isGlobal = get(this.props, 'template.is_global');
    const isDraft = get(this.props, 'template.is_draft');
    const user = this.props.loggedInUser;
    const isAdmin = hasAccess(user)('templates_notices');
    const hasTemplateRights = isAdmin || get(this.props, 'template.created_by') === user.id;

    return (
      <Fragment>
        <Scoped css={styles}>
          <div
            className={a('templateRow').m(
              'templateRowSelected',
              this.props.selectedNoticeTemplate && this.props.selectedNoticeTemplate.id === this.props.template.id
            )}
            onClick={this.maybeRouteToEdit}>
            <div className="leftSection">
              <div className="cps-subheader-sm">{this.props.template.name}</div>
              <div className="template-info cp-color-app-secondary-text">{this.getInfo()}</div>
            </div>

            <div className="rightSection">
              {!this.state.loading ? (
                <CpDropdown
                  position="bottom-end"
                  renderTrigger={({ toggle }) => <CpButton icon="misc-kabob" onClick={toggle} aria-label="template-options" />}
                  renderContent={() => (
                    <div className="cp-select-list">
                      {((!this.props.smeEdit && !isGlobal && !isDraft && hasTemplateRights) || this.props.smeEdit) && (
                        // show if we are in sme manager or if template is a copied version
                        <button onClick={() => this.routeToEdit(this.props.template.id)}>
                          <CpIcon name="crud-pencil" className="cp-select-list__icon-left" />
                          Edit
                        </button>
                      )}
                      {((!this.props.smeEdit && isGlobal) || (!this.props.smeEdit && isDraft) || !hasTemplateRights) && (
                        // show if we are not in sme manager and the template is a global template
                        <button onClick={() => this.routeToEdit(this.props.template.id)}>
                          <CpIcon name="misc-open-eye" className="cp-select-list__icon-left" />
                          View
                        </button>
                      )}
                      {!this.props.smeEdit && isGlobal && (
                        // show if we are not in sme manager and the template is a global template
                        <button onClick={this.customizeTemplate}>
                          <CpIcon name="crud-duplicate" className="cp-select-list__icon-left" />
                          Customize
                        </button>
                      )}
                      {!this.props.smeEdit && !isGlobal && !isDraft && hasTemplateRights && (
                        // show if we are not in sme manager and the template is a copied version
                        <button
                          onClick={() => {
                            this.deleteTemplate({ restoring: true });
                          }}>
                          <CpIcon name="af-refresh" className="cp-select-list__icon-left" />
                          Restore to original
                        </button>
                      )}
                      {this.props.smeEdit && !this.props.isPublished && (
                        // show if we are in sme manager and we are not in published tab
                        <button onClick={this.showCopyModal}>
                          <CpIcon name="crud-duplicate" className="cp-select-list__icon-left" />
                          Copy template
                        </button>
                      )}
                      {this.props.smeEdit && !this.props.isPublished && (
                        // show if we are in sme manager and we are not in published tab
                        <button
                          onClick={() => {
                            this.deleteTemplate({ restoring: false });
                          }}>
                          <CpIcon name="crud-trash-small" className="cp-select-list__icon-left" />
                          Delete template
                        </button>
                      )}
                    </div>
                  )}
                />
              ) : (
                <CpLoader />
              )}
            </div>
          </div>
          {this.state.copyModal ? (
            <CopyTempalateModal
              template={this.props.template}
              smeEdit={this.props.smeEdit}
              cancel={this.hideCopyModal}
              confirm={this.copyModal}
              refetchNotices={this.props.refetchNotices}
              onFocus={this.visibleIcons}
              onBlur={this.invisibleIcons}
            />
          ) : null}
          {this.state.deleteConfirm ? (
            <DeleteTemplateConfirmationModal
              template={this.props.template}
              cancel={this.hideDeleteModal}
              confirm={this.actuallyDelete}
              refetchNotices={this.props.refetchNotices}
              onFocus={this.visibleIcons}
              onBlur={this.invisibleIcons}
              restoring={this.state.restoring}
            />
          ) : null}
        </Scoped>
      </Fragment>
    );
  }

  visibleIcons = () => {
    this.setState({ visibleIcons: true });
  };

  invisibleIcons = () => {
    this.setState({ visibleIcons: false });
  };

  deleteTemplate = ({ restoring }) => {
    this.setState({ deleteConfirm: true, restoring });
  };

  showCopyModal = () => {
    this.setState({ copyModal: true });
  };

  copyModal = name => {
    let newName = name;
    if (newName === undefined || newName === '') {
      newName = `${this.props.template.name} - copy`;
    }
    return copyAndSaveNoticeTemplate(this.props.template.id, newName);
  };

  customizeTemplate = () => {
    this.setState({ loading: true });
    this.props.cancelWhenUnmounted(
      customizeTemplate(this.props.template.id).subscribe(template => {
        if (template.copying_document_template_error) {
          warningToast({ message: 'Some documents were unable to transfer to your customized notice template' });
        }
        this.routeToEdit(template.id);
      }, handleError)
    );
  };

  hideCopyModal = () => {
    this.setState({ copyModal: false });
  };

  hideDeleteModal = () => {
    this.setState({ deleteConfirm: false, restoring: false });
  };

  actuallyDelete = () => {
    return deleteNoticeTemplate(this.props.template.id);
  };

  routeToEdit = templateId => {
    window.location.href = `#/notices/global-settings/template/${templateId}/edit${this.props.smeEdit ? `-sme` : ''}`;
  };

  maybeRouteToEdit = () => {
    this.props.handleNoticeClick && this.props.handleNoticeClick(this.props.template);
  };

  getInfo = () => {
    const template = this.props.template;
    if (template.is_global && !this.props.smeEdit) {
      return 'Original unedited template created by Canopy';
    } else {
      const user = get(
        find(this.props.teamMembers, tm => tm.id === template.updated_by),
        'name',
        'unknown'
      );
      return `Template last edited on ${DateTime.fromISO(template.updated_at).toLocaleString(DateTime.DATE_MED)} by ${user}`;
    }
  };
}
