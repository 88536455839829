import { pluck } from 'rxjs/operators';
// project
import { convertExistingToNew } from './template-editor.helper.js';
// sofe
import { fetchAsObservable } from 'fetcher!sofe';
import canopyUrls from 'canopy-urls!sofe';

export function getRevisionsList(version) {
  return fetchAsObservable(`/versions/${version}`).pipe(pluck('versions'));
}

export function getDateTypes(userId) {
  if (!userId) {
    throw new Error('cannot fetch dateTypes without a userID');
  }

  return fetchAsObservable(`/api/date-types`).pipe(pluck('date_types'));
}

export function saveNoticeTemplate(template, isGlobal = false, isNew = false) {
  template.name = template.name.trim();
  template.subtasks = template.subtasks?.map(subtask => ({ ...subtask, name: subtask.name.trim() }));
  if (isGlobal || isNew) {
    template.is_editable_by_all = true;
    return postNewNoticeTemplate(template);
  } else {
    return patchTemplate(template);
  }
}

export function publishGlobalTemplate(templateId) {
  if (!templateId) {
    throw new Error('Template id is require to publish a template as a canopy global');
  }
  // POST /api/publish-canopy-global-template/:templateId
  return fetchAsObservable(`/api/publish-canopy-global-template/${templateId}`, {
    method: 'POST'
  });
}

export function deleteNoticeTemplate(templateId) {
  if (!templateId) {
    throw new Error('Template id is require to delete a template');
  }
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/template-tasks/${templateId}`, { method: 'DELETE' });
}

export function deleteAccusoftDocs(document_templates) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/documents_delete`, { method: 'DELETE', body: { document_templates } });
}

function patchTemplate(task) {
  const config = {
    method: 'PATCH',
    body: {
      template_tasks: task
    }
  };
  return fetchAsObservable(`/api/template-tasks/${task.id}`, config);
}

function postNewNoticeTemplate(task) {
  const newTask = convertExistingToNew(task);
  const config = {
    method: 'POST',
    body: {
      template_tasks: newTask
    }
  };
  return fetchAsObservable(`/api/template-tasks`, config);
}
