import * as React from 'react';
import Cancelable from 'react-disposable-decorator';
import { handleError } from 'src/handle-error';
import { Link } from 'react-router-dom';
import { Scoped, a } from 'kremling';
// project
import { getTeamMembers, getNoticeTypes } from 'src/common/common.resource.js';
import NoticeTemplateRow from '../templates-list/notice-template-row/notice-template-row.component.js';
import css from './sme-template-manager.krem.css';
import { filterTemplatesByStatus } from 'src/common/common.helpers.js';
// sofe
import { NavContent } from 'primary-navbar!sofe';
import { CpLoader, CpEmptyState } from 'canopy-styleguide!sofe';

const tabs = [
  {
    icon: 'draft',
    name: 'In Progress',
    key: 'inprogress'
  },
  {
    icon: 'status',
    name: 'Ready',
    key: 'ready'
  },
  {
    icon: 'lg-check',
    name: 'Published',
    key: 'published'
  }
];

@Cancelable
export default class SMETemplateManager extends React.Component {
  static defaultProps = {
    cancelWhenUnmounted: () => {}
  };

  state = {
    noticeTemplates: [],
    teamMembers: [],
    loadingTemplates: true,
    loadingTeamMembers: true,
    searchValue: '',
    filteredTemplates: [],
    activeTab: 'inprogress'
  };

  componentDidMount() {
    this.props.cancelWhenUnmounted(
      getTeamMembers().subscribe(results => {
        this.setState({ teamMembers: results, loadingTeamMembers: false });
      })
    );
    this.fetchNotices();
  }

  render() {
    const { noticeTemplates } = this.state;
    return (
      <NavContent hasTopnavSecondary={true}>
        <Scoped css={css}>
          <div className="cps-flexible-focus">
            <Link className="cps-color-vibrant-ocean" to="/notices/global-settings">
              {'< Return to templates'}
            </Link>
            <div className="NoticeBuilderHeader">
              <div className="cps-subheader">SME Notice Builder</div>
              <Link className="cps-btn +primary" to="/notices/global-settings/template/new">
                Create New
              </Link>
            </div>

            <div className="cps-bg-white cps-card__height-2">
              <div className="SMENoticeTabs">
                {tabs.map(tab => (
                  <a
                    onClick={() => this.setActiveTab(tab.key)}
                    key={`${tab.key}`}
                    className={a('SMENoticeTab cps-body').m('noticeTabSelected', this.state.activeTab === tab.key)}>
                    <i className={`cps-icon cps-icon-${tab.icon} cps-color-secondary-text`}></i>
                    <div className="cp-pl-16">{tab.name}</div>
                  </a>
                ))}
              </div>
              <div className="searchBox cps-bg-background">
                {this.state.searchValue === '' ? (
                  <i className="templateSearchIcon cps-icon cps-icon-search cps-color-secondary-text"></i>
                ) : (
                  <i className="templateSearchIcon cps-icon cps-icon-close +pointer cps-color-secondary-text" onClick={this.clearSearch} />
                )}
                <input
                  className="templateSearch"
                  placeholder="Search notice templates"
                  value={this.state.searchValue}
                  onChange={this.handleSearchValueChange}
                />
              </div>
              <div>{this.tableContents(noticeTemplates)}</div>
            </div>
          </div>
        </Scoped>
      </NavContent>
    );
  }

  tableContents = noticeTemplates => {
    if (this.state.loadingTemplates || this.state.loadingTeamMembers) {
      return (
        <div className="cp-p-16">
          <CpLoader />
        </div>
      );
    } else if (noticeTemplates.length <= 0) {
      return <CpEmptyState img="es_porg" text="This porg finds your lack of notices disturbing." className="cp-p-16" />;
    } else {
      return noticeTemplates.map((template, index) => (
        <React.Fragment key={template.id}>
          <NoticeTemplateRow
            template={template}
            teamMembers={this.state.teamMembers}
            smeEdit={true}
            refetchNotices={this.fetchNotices}
            isPublished={this.state.activeTab === 'published'}
          />
          {index + 1 !== noticeTemplates.length ? <hr className="cp-m-0" /> : null}
        </React.Fragment>
      ));
    }
  };

  setActiveTab = key => {
    this.setState({ activeTab: key, loadingTemplates: true }, this.fetchNotices());
  };

  handleSearchValueChange = evt => {
    const value = evt.target.value;
    this.setState({ searchValue: value }, this.filterNoticeTemplates);
  };

  fetchNotices = () => {
    this.props.cancelWhenUnmounted(
      getNoticeTypes(undefined).subscribe(results => {
        const statusFiltered = this.filterTemplatesByStatus(results);
        this.setState({
          noticeTemplates: statusFiltered,
          loadingTemplates: false,
          filteredTemplates: statusFiltered
        });
      }, handleError)
    );
  };

  clearSearch = () => {
    this.setState(prev => {
      return {
        searchValue: '',
        filteredTemplates: prev.noticeTemplates
      };
    });
  };

  filterNoticeTemplates = () => {
    this.setState(prev => {
      const filteredTemplates = prev.noticeTemplates.filter(noticeTemplate => {
        return noticeTemplate.name.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1;
      });
      return {
        filteredTemplates
      };
    });
  };

  filterTemplatesByStatus = templates => {
    return filterTemplatesByStatus(templates, this.state.activeTab);
  };
}
