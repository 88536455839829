import React from 'react';
import Cancelable from 'react-disposable-decorator';
import { handleError } from 'src/handle-error';
// sofe imports
import { CpButton, CpModal, CpInput } from 'canopy-styleguide!sofe';
import { successToast } from 'toast-service!sofe';

@Cancelable
export default class CopyTemplateModal extends React.Component {
  state = {
    isCopying: false,
    newName: ''
  };

  static defaultProps = {
    cancelWhenUnmounted: () => {}
  };

  render() {
    return (
      <CpModal show={true} onClose={this.cancel}>
        <CpModal.Header title={`Copy ${this.props.template.name}?`} />
        <CpModal.Body>
          <label>
            Notice Type - <span className="cp-color-app-warning-text">This CANNOT be changed after copy</span>
          </label>
          <CpInput placeholder={`${this.props.template.name} - copy`} value={this.state.newName} onChange={this.handleNameChange} />
        </CpModal.Body>
        <CpModal.Footer>
          <CpButton btnType="primary" onClick={this.confirm} className="cp-mr-8" showLoader={this.state.isCopying}>
            Copy
          </CpButton>
          <CpButton btnType="flat" onClick={this.cancel} disabled={this.state.isCopying}>
            Cancel
          </CpButton>
        </CpModal.Footer>
      </CpModal>
    );
  }

  handleNameChange = newName => {
    this.setState({ newName });
  };

  cancel = () => {
    if (!this.state.isCopying) {
      this.props.cancel();
    }
  };

  confirm = evt => {
    evt.preventDefault();
    this.setState({ isCopying: true }, () => {
      this.props.cancelWhenUnmounted(
        this.props.confirm(this.state.newName).subscribe(
          response => {
            const newTemplate = response.template_tasks;
            this.setState({ isCopying: false }, () => {
              successToast({ message: `Notice titled "${this.props.template.name}" was duplicated.` });
              this.cancel();
              window.location.href = `#/notices/global-settings/template/${newTemplate.id}/edit${this.props.smeEdit ? `-sme` : ''}`;
            });
          },
          err => {
            this.setState({ isCopying: false });
            handleError(err);
          }
        )
      );
    });
  };
}
