import React from 'react';
import Cancelable from 'react-disposable-decorator';
import { handleError } from 'src/handle-error';
// sofe imports
import { CpButton, CpModal } from 'canopy-styleguide!sofe';
import { successToast, warningToast } from 'toast-service!sofe';

@Cancelable
export default class DeleteNoticeTemplateConfirmationModal extends React.Component {
  state = {
    isDeleting: false
  };

  static defaultProps = {
    cancelWhenUnmounted: () => {}
  };

  render() {
    const { restoring } = this.props;

    return (
      <CpModal show={true} onClose={this.cancel}>
        <CpModal.Header title={restoring ? `Restore ${this.props.template.name} to original?` : `Delete ${this.props.template.name}?`} />
        <CpModal.Body>
          {restoring ? (
            <div>
              Are you sure you want to restore &quot;{this.props.template.name}&quot; the original template? This action cannot be undone.
            </div>
          ) : (
            <div>Are you sure you want to delete &quot;{this.props.template.name}&quot;? This action cannot be undone.</div>
          )}
        </CpModal.Body>
        <CpModal.Footer>
          <CpButton btnType="primary" onClick={this.confirm} className="cp-mr-8" showLoader={this.state.isDeleting}>
            {restoring ? 'Restore original' : 'Delete'}
          </CpButton>
          <CpButton btnType="flat" onClick={this.cancel} disabled={this.state.isDeleting}>
            Cancel
          </CpButton>
        </CpModal.Footer>
      </CpModal>
    );
  }

  cancel = () => {
    if (!this.state.isDeleting) {
      this.props.cancel();
    }
  };

  confirm = () => {
    this.setState({ isDeleting: true }, () => {
      this.props.cancelWhenUnmounted(
        this.props.confirm().subscribe(
          () => {
            this.setState({ isDeleting: false }, () => {
              successToast({
                message: `Notice titled "${this.props.template.name}" was ${this.props.restoring ? 'restored to original' : 'deleted'}.`
              });
              this.props.refetchNotices && this.props.refetchNotices();
              this.cancel();
            });
          },
          err => {
            if (err.status === 403) {
              warningToast({ message: `You cannot delete "${this.props.template.name}" because you didn't create it.` });
            }
            this.setState({ isDeleting: false });
            handleError(err);
          }
        )
      );
    });
  };
}
