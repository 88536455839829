import React from 'react';
import { HashRouter, Route, Redirect } from 'react-router-dom';
import { UserTenantProps, hasAccess } from 'cp-client-auth!sofe';

import TemplateRoutes from './templates/template-routes.component.js';

@UserTenantProps()
export default class Routes extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const hasNoticesPermission = hasAccess(this.props.loggedInUser)('templates_notices');

    return hasNoticesPermission ? (
      <HashRouter>
        <div>
          <Route path="/notices/clients/:clientId" render={({ match }) => <Redirect to={`/tasks/clients/${match.params.clientId}`} />} />
          <Route path="/notices/dashboard">
            <Redirect to="/tasks/dashboard" />
          </Route>
          <Route path="/notices/global-settings" render={() => <TemplateRoutes />} />
        </div>
      </HashRouter>
    ) : (
      <HashRouter>
        <Redirect to="/403" />
      </HashRouter>
    );
  }
}
