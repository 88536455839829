import * as React from 'react';
import { get } from 'lodash';
import Dropzone from 'react-dropzone';
import { Scoped, k, always } from 'kremling';
import { CpButton, CpLoader } from 'canopy-styleguide!sofe';
import Cancelable from 'react-disposable-decorator';

import AddFormModal from './add-form-modal.component';
import { handleError } from '../handle-error.js';

// project imports
import { addFileRelationToTask, uploadDocument } from './task-methods.resource.js';

@Cancelable
export default class FileUploader extends React.Component {
  static defaultProps = {
    sync: false,
    show: false
  };

  state = {
    document: null,
    showDocxUploader: false,
    showFormUploader: false,
    loading: false
  };

  componentDidUpdate(prevProps) {
    if ((!prevProps.show && this.props.show) || this.props.uploaderType !== prevProps.uploaderType) {
      if (this.props.uploaderType === 'editable_doc') {
        this.setState({ showDocxUploader: true, showFormUploader: false });
        if (prevProps.uploaderType === 'file') {
          this.closeImportDrawer();
        }
      } else if (this.props.uploaderType === 'editable_form') {
        this.setState({ showFormUploader: true, showDocxUploader: false });
        if (prevProps.uploaderType === 'file') {
          this.closeImportDrawer();
        }
      } else {
        this.setState({ showFormUploader: false, showDocxUploader: false });
        this.importDrawer();
      }
    } else if (prevProps.show && !this.props.show) {
      this.setState({ showDocxUploader: false, showFormUploader: false });
      this.closeImportDrawer();
    }
  }

  closeImportDrawer = () => {
    if (this.inboxActions && this.inboxActions.close) {
      this.inboxActions.close();
    }
  };

  handleFiles = files => {
    if (this.props.sync) {
      this.props.addFileRelationship(this.props.task.id, files);
    } else {
      this.attachFilesToTask(files);
    }
  };

  attachFilesToTask = files => {
    this.props.cancelWhenUnmounted(
      addFileRelationToTask(this.props.task, files).subscribe(results => {
        const resultFiles = get(results, 'tasks[0].relationships.files', files);
        this.props.addFileRelationship(this.props.task.id, resultFiles);
        this.props.onClose();
      }, handleError)
    );
  };

  importDrawer = () => {
    SystemJS.import('docs-ui!sofe').then(docsUI => {
      docsUI.loadInboxDrawer().then(inboxDrawer => {
        inboxDrawer({
          type: 'upload',
          entity: 'task',
          submitButtonText: 'Done',
          setInboxActions: this.setInboxActions,
          onClose: () => {
            if (this.props.uploaderType === 'file') {
              this.props.onClose();
            }
          },
          clientId: this.props.clientId,
          hidden: true
        }).subscribe(this.handleFiles);
      });
    });
  };

  setInboxActions = actions => {
    this.inboxActions = actions;
  };

  handleDrop = file => {
    if (file.length) {
      this.setState({ document: file[0], loading: true }, this.upload(file[0]));
    }
  };

  upload = document => {
    this.props.cancelWhenUnmounted(
      uploadDocument(document, this.props.purpose).subscribe(
        docId => {
          this.setState({ showDocxUploader: false, loading: false }, () => setTimeout(this.props.onClose, 500));
          this.props.addFileRelationship(this.props.task.id, { id: docId, filename: document.name, editable: true }, 'document_templates');
        },
        err => {
          err.toastMessage = `We were unable to attach the file to the template`;
          this.setState({ showDocxUploader: false, loading: false }, () => setTimeout(this.props.onClose, 500));
          handleError(err);
        }
      )
    );
  };

  addFormsToTemplate = forms => {
    this.props.addFileRelationship(this.props.task.id, forms, 'forms');
    this.props.onClose();
  };

  documentUploadContents = () => {
    if (this.state.loading) {
      return (
        <div className="documents-file-upload">
          <div className="du-loading-state">
            <img width="100" src="https://cdn.canopytax.com/static/empty-states/es_files_upload.svg" alt="uploading file" />
            <div style={{ fontSize: 18, marginBottom: 18 }}>Uploading document</div>
            <CpLoader dark size="md" />
          </div>
        </div>
      );
    }

    return (
      <div className="documents-file-upload">
        <img width="270" src="https://cdn.canopytax.com/images/empty-states/drag-n-drop-empty-state-wire.svg" alt="Drag and drop" />
        <div
          style={{
            color: 'rgb(82, 85, 90)',
            fontSize: '19px',
            fontWeight: 'bold',
            padding: '16px 5px 0'
          }}>
          Drag and drop files
        </div>
        <div
          style={{
            color: 'rgb(119, 119, 119)',
            fontWeight: 'normal'
          }}>
          To be editable, files must be a <b>.docx</b> file
        </div>
        <CpButton btnType="secondary">Select file</CpButton>
      </div>
    );
  };

  render() {
    const { showDocxUploader, showFormUploader, document } = this.state;
    return (
      <>
        <Scoped css={css}>
          <div className={always('docx-uploader').m('show-docx-uploader', showDocxUploader)}>
            <div className="du-header">
              <div style={{ display: 'flex' }}>
                <img
                  src="https://s3-us-west-2.amazonaws.com/cdn.canopytax.com/images/files-inbox-blue.svg"
                  height={19}
                  style={{ margin: '4px 8px 0 0' }}
                />
                <div>Upload editable files</div>
              </div>
              <CpButton
                icon="close-large"
                btnType="icon-dark"
                aria-label="Close file inbox"
                onClick={() => this.setState({ showDocxUploader: false }, () => setTimeout(this.props.onClose, 500))}
              />
            </div>
            <div className="du-modal-body">
              {!document ? (
                <Dropzone
                  onDrop={this.handleDrop}
                  multiple={false}
                  accept={{ 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'] }}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} style={{ outline: 'none' }}>
                      <input {...getInputProps()} />
                      {this.documentUploadContents()}
                    </div>
                  )}
                </Dropzone>
              ) : (
                this.documentUploadContents()
              )}
            </div>
          </div>
        </Scoped>
        <AddFormModal
          showFormUploader={showFormUploader}
          onClose={() => this.setState({ showFormUploader: false }, this.props.onClose)}
          addFormsToTemplate={this.addFormsToTemplate}
          revision={this.props.revision}
        />
      </>
    );
  }
}

const css = k`
  .docx-uploader {
    width: 354px;
    position: fixed;
    top: 15%;
    max-height: 90vh;
    z-index: 100010;
    background-color: #1f1e29;
    border-radius: 5px;
    right: -60rem;
  }

  .du-header {
    width: 100%;
    padding: 12px;
    border-bottom: solid 1px white;
    display: flex;
    color: var(--cps-color-bumble);
    font-size: 19px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
  }

  .du-loading-state {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .du-modal-body {
    padding: 16px;
  }

  .documents-file-upload {
    height: 410px;
    border: 1px solid rgb(233, 233, 233);
    background: var(--cps-color-athens);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .show-docx-uploader {
    right: 12px;
    transition: 1.2s ease-in-out;
  }
`;
