import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import TemplateList from './templates-list/templates-list.component.js';
import TemplateEditor from './template-editor/template-editor.component.js';
import SMETemplateManager from './sme-template-manager/sme-template-manager.component.js';
import { get } from 'lodash';
// sofe
import { hasAccess, UserTenantProps } from 'cp-client-auth!sofe';
import { warningToast } from 'toast-service!sofe';

@UserTenantProps()
export default class TemplateRoutes extends React.Component {
  render() {
    return (
      <div>
        <Route
          render={({ match }) => {
            const user = this.props.loggedInUser;
            if (hasAccess(user)('tasks_notices')) {
              return (
                <div>
                  <Route path={`${match.path}`} exact={true} component={TemplateList} />
                  <Route path={`${match.path}/template/:templateId/edit`} exact={true} render={props => <TemplateEditor {...props} />} />
                  <Route
                    path={`${match.path}/sme`}
                    exact={true}
                    render={props => {
                      if (!hasAccess(user)('sme_notices')) {
                        return this.invalidPermissions(`${match.path}`);
                      } else {
                        return <SMETemplateManager {...props} />;
                      }
                    }}
                  />
                  <Route
                    path={`${match.path}/template/:templateId/edit-sme`}
                    exact={true}
                    render={props => {
                      const templateId = get(props, 'match.params.templateId');
                      if (!hasAccess(user)('sme_notices')) {
                        return this.invalidPermissions(`${match.path}/template/${templateId}/edit`);
                      } else {
                        return <TemplateEditor {...props} smeEdit={true} />;
                      }
                    }}
                  />
                  <Route
                    path={`${match.path}/template/new`}
                    exact={true}
                    render={props => {
                      if (!hasAccess(user)('sme_notices')) {
                        return this.invalidPermissions(`${match.path}`);
                      } else {
                        return <TemplateEditor {...props} new={true} smeEdit={true} />;
                      }
                    }}
                  />
                </div>
              );
            } else {
              return <Redirect to="/" />;
            }
          }}
        />
      </div>
    );
  }

  invalidPermissions = redirectPage => {
    warningToast({ message: "You don't have permissions to view that page" });
    return <Redirect to={`${redirectPage}`} />;
  };
}
