import React, { Suspense, lazy, createRef } from 'react';
import { Scoped, maybe } from 'kremling';
import { get } from 'lodash';
import { CpIcon, CpLoader } from 'canopy-styleguide!sofe';

// project
import css from './template-card.krem.css';
import { ToolsSelect } from '../tools-select/tools-select.component.js';
import AddFileButton from '../add-file-button/add-file-button.component.js';
import { filterFiles, filterDocs, filterForms } from '../template-editor.helper';
import FileCard from '../../../common/file-card.component';

const DocumentEditorOverlay = lazy(() =>
  SystemJS.import('document-editor-ui!sofe')
    .then(deUI => deUI.getDocumentEditorOverlay())
    .then(modal => modal)
);

const CpRichTextEditor = lazy(() =>
  import('rich-text-ui!sofe')
    .then(module => module.getRichTextEditor())
    .then(rte => rte)
);

export default class TemplateCard extends React.Component {
  state = {
    name: '',
    description: '<p></p>',
    selectedTools: [],
    fileRelationships: [],
    formRelationships: [],
    docRelationships: [],
    prevDescription: undefined,
    showDocEditor: false,
    docxFile: {},
    createNew: false
  };

  editorRef = createRef();

  static getDerivedStateFromProps(nextProps, prevState) {
    const stateUpdate = {
      name: nextProps.task.name,
      description: nextProps.task.description || '<p></p>',
      selectedTools: nextProps.task.tools,
      fileRelationships: (prevState && prevState.fileRelationships) || [],
      docRelationships: (prevState && prevState.docRelationships) || [],
      formRelationships: (prevState && prevState.formRelationships) || [],
      prevDescription: get(nextProps, 'task.description')
    };

    const fileRelationships = get(nextProps, 'task.relationships.files', []);
    stateUpdate.fileRelationships = filterFiles(fileRelationships).filter(file => !file.delete && !file.blacklisted);
    const docRelationships = get(nextProps, 'task.relationships.document_templates', []);
    stateUpdate.docRelationships = filterDocs(docRelationships).filter(doc => !doc.delete);
    const formRelationships = get(nextProps, 'task.relationships.forms', []);
    stateUpdate.formRelationships = filterForms(formRelationships).filter(form => !form.delete);
    return stateUpdate;
  }

  render() {
    const { name, fileRelationships, docRelationships, formRelationships } = this.state;
    const hasFiles = fileRelationships.length > 0 || docRelationships.length > 0 || formRelationships.length > 0;

    return (
      <Scoped css={css}>
        <div className="taskCard cps-card__height-2">
          <div className="cps-subheader cp-pl-24 cp-pt-16 cp-pb-16 flex-apart">
            <div className="taskCardTitle">
              <span className="cp-pr-24 cps-icon cps-icon-taxes cps-color-secondary-text"></span>
              <div className="taskCardName">{name || 'Subtask Title'}</div>
            </div>
            {this.props.isSub && !this.props.viewOnly ? (
              <div className="cps-btn-icon cp-mr-16">
                <a className="cps-link cp-mt-0" onClick={this.removeSub}>
                  <span className="cps-icon cps-icon-trash cps-color-secondary-text"></span>
                </a>
              </div>
            ) : null}
          </div>
          <hr className="cp-m-0" />
          <div className="taskCardContents">
            <Suspense fallback={<></>}>
              <DocumentEditorOverlay
                show={this.state.showDocEditor}
                documentId={this.state.docxFile.id}
                title={this.state.docxFile.filename}
                mode="notice_template"
                onClose={() => {
                  this.setState({ showDocEditor: false, docxFile: {}, createNew: false });
                  this.props.handleHideScrollbar({ hideScrollbar: false });
                }}
              />
            </Suspense>
            {this.props.isSub ? this.getSubContents() : this.getContents()}
            {hasFiles && (
              <div className="addedFiles cps-bg-gray6 cp-mt-16 cp-pb-16">
                <div className="fileBlockTitle cp-pt-16 cp-pb-16">
                  <CpIcon name="file" />
                  <div style={{ margin: '4px' }}>Added Files</div>
                </div>
                <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
                  {docRelationships.map(document => {
                    return (
                      <FileCard
                        key={document.id}
                        file={document}
                        handleEditFile={document => {
                          this.setState({ showDocEditor: true, docxFile: document });
                          this.props.handleHideScrollbar({ hideScrollbar: true });
                        }}
                        handleRemoveFile={this.handleRemoveDocument}
                        viewOnly={this.props.viewOnly}
                        relationshipType="document_template"
                        revision={this.props.revision}
                      />
                    );
                  })}
                  {fileRelationships.map(file => (
                    <FileCard
                      key={file.id}
                      file={file}
                      handleRemoveFile={this.handleRemoveFile}
                      viewOnly={this.props.viewOnly}
                      relationshipType="file"
                      revision={this.props.revision}
                    />
                  ))}
                  {formRelationships.map(form => (
                    <FileCard
                      key={form.id}
                      file={form}
                      handleRemoveFile={this.handleRemoveForm}
                      viewOnly={this.props.viewOnly}
                      relationshipType="form"
                      revision={this.props.revision}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </Scoped>
    );
  }

  getSubContents = () => {
    const { description } = this.state;
    return (
      <div className="flex">
        <div className="leftTaskCardArea">{this.getSubLeft()}</div>
        <div className="rightTaskCardArea">
          <span>{this.props.isSub ? 'Subtask description' : 'Template Description'}</span>
          <Suspense fallback={<CpLoader />}>
            <div className={maybe('disabledRichTextCustomHeight', this.props.viewOnly).maybe('richTextCustomHeight', !this.props.viewOnly)}>
              <CpRichTextEditor
                initialHTML={description}
                editorRef={this.editorRef}
                onInput={this.updateDescription}
                placeholder="add description"
                disabled={this.props.viewOnly}
                buttons={['bold', 'underline', 'italic', 'list-unordered', 'list-ordered', 'link']}
              />
            </div>
          </Suspense>
        </div>
      </div>
    );
  };

  getContents = () => {
    const { description } = this.state;
    const shouldAllowNameEdit = this.props.new && this.props.smeEdit;
    return (
      <div>
        {shouldAllowNameEdit ? (
          <div className="cps-form-group">
            <label>
              Notice Type - <span className="cps-red">This CANNOT be changed after being saved</span>
            </label>
            <input type="text" className="cps-form-control" placeholder="Notice type" value={this.state.name} onChange={this.updateName} />
          </div>
        ) : null}
        {this.props.new || this.props.smeEdit ? (
          <div className="cps-form-group">
            <label>Notice Explanation</label>
            <input
              type="text"
              className="cps-form-control"
              placeholder="Notice Explanation"
              value={this.props.task.service_fields.notice_explanation}
              onChange={this.updateExplanation}
            />
          </div>
        ) : null}
        <span>Template Description</span>
        <Suspense fallback={<CpLoader />}>
          <div className={maybe('disabledRichTextCustomHeight', this.props.viewOnly).maybe('richTextCustomHeight', !this.props.viewOnly)}>
            <CpRichTextEditor
              initialHTML={description}
              editorRef={this.editorRef}
              onInput={this.updateDescription}
              placeholder="add description"
              disabled={this.props.viewOnly}
              buttons={['bold', 'underline', 'italic', 'list-unordered', 'list-ordered', 'link']}
            />
          </div>
        </Suspense>
        {!this.props.viewOnly && (
          <div className="cp-pt-16">
            <AddFileButton
              id={this.props.task.id}
              addRelationship={this.props.addRelationship}
              task={this.props.task}
              purpose={this.props.smeEdit ? 'canopy_template' : 'practitioner_template'}
              revision={this.props.revision}
            />
          </div>
        )}
      </div>
    );
  };

  getSubLeft = () => {
    return (
      <div className="cp-pr-24 height-100 relative">
        <div className="cps-form-group">
          <label>Subtask name</label>
          <input
            type="text"
            className="cps-form-control"
            placeholder="Subtask name"
            value={this.state.name}
            onChange={this.updateName}
            disabled={this.props.viewOnly}
          />
        </div>
        <ToolsSelect
          tools={this.state.selectedTools}
          id={this.props.task.id}
          updateTools={this.props.updateTools}
          viewOnly={this.props.viewOnly}
        />
        {!this.props.viewOnly && (
          <div className="abs-bottom">
            <AddFileButton
              id={this.props.task.id}
              addRelationship={this.props.addRelationship}
              task={this.props.task}
              purpose={this.props.smeEdit ? 'canopy_template' : 'practitioner_template'}
              revision={this.props.revision}
            />
          </div>
        )}
      </div>
    );
  };

  // this is used in the edit-template-cards component apparently - dont delete
  getDescription = () => {
    return this.state.description;
  };

  updateName = event => {
    const value = event.target.value;
    if (value.length <= 500) {
      this.props.updateName(this.props.task.id, value);
    }
  };

  updateExplanation = event => {
    const value = event.target.value;
    this.props.updateExplanation && this.props.updateExplanation(this.props.task.id, value);
  };

  updateDescription = () => {
    this.props.updateDescription(this.props.task.id, this.editorRef.current.getHTML());
  };

  handleRemoveFile = file => {
    this.props.removeRelationship(this.props.task.id, file.id);
  };

  handleRemoveDocument = doc => {
    this.props.removeRelationship(this.props.task.id, doc.id, 'document_templates');
  };

  handleRemoveForm = form => {
    this.props.removeRelationship(this.props.task.id, form.id, 'forms');
  };

  removeSub = () => {
    this.props.removeSubtask && this.props.removeSubtask(this.props.task.id);
  };
}
